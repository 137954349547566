import { FieldsState } from '../../models/fields/fields';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { changeFieldsAction, setFields } from '../../actions/fields/fields';
import { FieldI } from '../../../core/entities/fields/fields';

const fieldsInitialState: FieldsState = {
  fields: [],
};

export const fieldsReducer = createReducer<FieldsState>(fieldsInitialState, {
  [setFields.type]: (state, action: PayloadAction<FieldI[]>) => {
    return {
      ...state,
      fields: action.payload,
    };
  },
  [changeFieldsAction.type]: (state, action: PayloadAction<FieldI[]>) => {
    return {
      ...state,
      fields: [...action.payload],
    };
  },
});
