import React from 'react';
import routes from '../../configs/routes.json';
import { RouteObject } from 'react-router-dom';

type Routes = {
  path?: string;
  component: string;
  children?: Routes[];
  index?: boolean;
};

export default function useRoutes(): RouteObject[] {
  return routes.map((route: Routes) => {
    const { path, component, children } = route;

    const lazyComponent =
      route.component === 'root'
        ? React.lazy(() => import(`../components/root-layout/root-layout`))
        : React.lazy(() => import(`../../pages/${component}/${component}`));

    return {
      path,
      element: React.createElement(lazyComponent),
      children: children?.map(mapRoutes),
    };
  });
}

function mapRoutes(child: Routes): any {
  const layout = child.component.split('.');
  const lazyComponentOrLayout =
    layout[0] === 'layouts'
      ? React.lazy(() => import(`../../layouts/${layout[1]}/${layout[1]}`))
      : React.lazy(() => import(`../../pages/${child.component}/${child.component}`));

  return {
    path: child.path,
    element: React.createElement(lazyComponentOrLayout),
    children: child.children?.map(mapRoutes),
    index: child?.index,
  };
}
