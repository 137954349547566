import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { getFavoriteColors, updateColor } from '../../actions/favorite-product/favorite-product';
import { ColorsFavoriteState } from '../../models/favorite-products/favorite-colors';
import { LabelColorI } from '../../../core/entities/product-favorite/label-colors';

const favoriteColorsInitialState: ColorsFavoriteState = {
  colors: Object(),
};

export const favoriteColorsReducer = createReducer<ColorsFavoriteState>(
  favoriteColorsInitialState,
  {
    [getFavoriteColors.type]: (state, action: PayloadAction<LabelColorI[]>) => ({
      ...state,
      colors: action.payload,
    }),
    [updateColor.type]: (state, action: PayloadAction<LabelColorI[]>) => ({
      ...state,
      labels: [...action.payload],
    }),
  },
);
