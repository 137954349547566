import { createAction } from '@reduxjs/toolkit';
import { QueryExtensionList } from '../../../../core/entities/promotion/query-extension';

export const setPromotionQueryExtensionList = createAction<QueryExtensionList>(
  'SET_PROMOTION_QUERY_EXTENSION_LIST',
);

export const setPromotionQueryExtensionCount = createAction<number>(
  'SET_PROMOTION_QUERY_EXTENSION_COUNT',
);
