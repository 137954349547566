export function nsCtor(namespace: string, separator = '.') {
  return (strings: TemplateStringsArray, ...values: unknown[]) => {
    let key: string;
    if (values.length > 0) {
      key = '';
      for (let i = 0; i < strings.length; i++) {
        const value = values[i];
        const str = strings[i];
        key += `${str || ''}${value != null ? value : ''}`;
      }
    } else {
      [key] = strings;
    }
    return `${namespace}${separator}${key}`;
  };
}
