import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QueryExtensionFilter } from '../../../../core/entities/promotion/query-extension';
import { initialFilterState } from '../../../../consts/promotion-queries';

const filterFieldsInitialState: QueryExtensionFilter = {
  ...initialFilterState,
};

const filterFieldsSlice = createSlice({
  name: 'filterFields',
  initialState: filterFieldsInitialState,
  reducers: {
    setQueryExtensionFilterState: (state, action: PayloadAction<QueryExtensionFilter>) => {
      return action.payload;
    },
    resetQueryExtensionFilterState: () => {
      return filterFieldsInitialState;
    },
  },
});

export const {
  setQueryExtensionFilterState,
  resetQueryExtensionFilterState,
} = filterFieldsSlice.actions;

export default filterFieldsSlice.reducer;
