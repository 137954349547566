import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DatesInitialState } from '../../models/calender/calendar';
import { lastYearRange } from '../../../consts/calendar';
import { toCalendarDate } from '../../../utils/date';

const datesInitialState: DatesInitialState = {
  dateTimeFrom: lastYearRange[0],
  dateTimeTo: lastYearRange[1],
};

export const calenderSlice = createSlice({
  name: 'calender',
  initialState: datesInitialState,
  reducers: {
    setCalender: (state, action: PayloadAction<DatesInitialState>) => ({
      ...state,
      dateTimeFrom: toCalendarDate(action.payload.dateTimeFrom),
      dateTimeTo: toCalendarDate(action.payload.dateTimeTo),
    }),
  },
});

export const { setCalender } = calenderSlice.actions;

export default calenderSlice.reducer;
