import React from 'react';
import AppRouter from './view/app-router';
import './shared/styles/index.scss';
import { store } from './store';
import { Provider } from 'react-redux';
import AuthProvider from './view/components/auth-provider/auth-provider';

function App() {
    // React.useEffect(() => {
    //     document.getElementById('root')!.addEventListener('mousemove', handleMouseMove);
    //     return () => document.getElementById('root')!.removeEventListener('mousemove', handleMouseMove);
    // }, []);

    const handleMouseMove = (e: any) => {
        const rootEl = document.getElementById('root')!;
        if (e.screenX  > window.innerWidth - 40) {
            rootEl.setAttribute('class', 'scrollable');
        } else {
            rootEl.removeAttribute('class');
        }
    }

  return (
    <Provider store={store}>
      <AuthProvider>
        <AppRouter />
      </AuthProvider>
    </Provider>
  );
}

export default App;
