import { PromotionQueriesState } from '../../../models/promotion/query-extensions';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
  setPromotionQueryExtensionCount,
  setPromotionQueryExtensionList,
} from '../../../actions/promotion/queries/query-extension';
import { QueryExtensionList } from '../../../../core/entities/promotion/query-extension';

const promotionQueriesState: PromotionQueriesState = {
  queryExtensionList: [],
  queryExtensionCount: 0,
};

export const promotionQueriesReducer = createReducer<PromotionQueriesState>(promotionQueriesState, {
  [setPromotionQueryExtensionList.type]: (state, action: PayloadAction<QueryExtensionList>) => {
    return { ...state, queryExtensionList: action.payload.queryExtensionList };
  },
  // [setFilterFields.type]: (state, action: PayloadAction<FilterFields>) => ({
  //   ...state,
  //   fields: action.payload,
  // }),
  [setPromotionQueryExtensionCount.type]: (state, action: PayloadAction<number>) => ({
    ...state,
    queryExtensionCount: action.payload,
  }),
});
