import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
  Brand,
  BrandField,
  BrandFilters,
  BrandPageInitState,
} from '../../../core/entities/brand/brand';
import {
  setBrandsCount,
  setBrandsFields,
  setBrandsFilters,
  // setBrandsFilterFields,
  setBrandsList,
  setBrandsStatisticsList,
} from '../../actions/brands/brand';

export const filterInitState = {
  desc: true,
  limit: 50,
  offset: 0,
  active: true,
};

const brandPageInitState: BrandPageInitState = {
  brandList: [],
  statisticsList: [],
  brandCount: 0,
  filtersFields: [], //todo do we need them???
  columnFields: [],
  filters: filterInitState,
};

export const analyticsBrandsReducer = createReducer<BrandPageInitState>(brandPageInitState, {
  [setBrandsList.type]: (state, action: PayloadAction<Brand[]>) => ({
    ...state,
    brandList: action.payload || [],
  }),
  // [setBrandsFilterFields.type]: (state, action: PayloadAction<BrandField[]>) => ({
  //   ...state,
  //   filtersFields: action.payload,
  // }),
  [setBrandsFields.type]: (state, action: PayloadAction<BrandField[]>) => ({
    ...state,
    columnFields: action.payload,
  }),
  [setBrandsCount.type]: (state, action: PayloadAction<number>) => ({
    ...state,
    brandCount: action.payload,
  }),
  [setBrandsFilters.type]: (state, action: PayloadAction<BrandFilters>) => ({
    ...state,
    filters: action.payload,
  }),
  [setBrandsStatisticsList.type]: (state, action: PayloadAction<Brand[]>) => ({
    ...state,
    statisticsList: action.payload || [],
  }),
});
