import { createAction } from '@reduxjs/toolkit';
import { ProductFavoriteI } from '../../../core/entities/product-favorite/product-favorite';
import { LabelColorI } from '../../../core/entities/product-favorite/label-colors';
import { FavoriteLabelI } from '../../../core/entities/product-favorite/favorite-labels';

export const getFavoriteProducts = createAction<ProductFavoriteI[]>('GET_FAVORIT_PRODUCTS');
export const getFavoriteColors = createAction<LabelColorI[]>('GET_FAVORIT_COLORS');
export const getFavoriteLabels = createAction<FavoriteLabelI[]>('GET_FAVORIT_LABELS');
export const updateLabels = createAction<FavoriteLabelI[]>('UPDATE_LABELS');
export const updateColor = createAction<LabelColorI[]>('UPDATE_COLORs');
export const updateFavoriteProducts = createAction<ProductFavoriteI[]>('UPDATE_FAVORITE_PRODUCTS');
