import { AuthenticationUseCase } from '../../core/use-cases/authentication/authentication';
import { authenticationFetch } from './authentication-fetch';
import { typedDispatch } from '../../store';
import { getToken, removeToken } from '../../store/reduces/authentication/authentication';
import { localStorageService } from '../local-storage';

const authenticationUseCase = new AuthenticationUseCase({
  authenticationService: {
    getListTitles: authenticationFetch.getListTitles.bind(authenticationFetch),
    login: authenticationFetch.login.bind(authenticationFetch),
    logout: authenticationFetch.logout.bind(authenticationFetch),
    registration: authenticationFetch.registration.bind(authenticationFetch),
    confirmEmail: authenticationFetch.confirmEmail.bind(authenticationFetch),
    saveTokenToStore(token: string) {
      typedDispatch(getToken(token));
    },
    removeTokenToStore() {
      typedDispatch(removeToken());
    },
    localStorageService: localStorageService,
  },
});

export const authenticationService = {
  login: authenticationUseCase.login.bind(authenticationUseCase),
  registration: authenticationUseCase.registration.bind(authenticationUseCase),
  confirmEmail: authenticationUseCase.confirmEmail.bind(authenticationUseCase),
  logout: () => authenticationUseCase.logout(),
  getListTitles: authenticationUseCase.getListTitles.bind(authenticationUseCase),
};
