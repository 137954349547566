import { defineHttpRequestError } from '../../core/use-cases/error-handler/differentiation-http-request-error';
import { HttpFetch, RequestConfig, QueryParamType } from '../../gateways/http-fetch/http-fetch';
import { HttpTimeoutError } from '../../core/use-cases/errors/http-timeout';
import { cookieStorage } from '../cookie-storage';
import { localStorageService } from '../local-storage';
import { app } from '../../consts/app';
import { AuthenticationI } from '../../core/entities/authentication/authentication';
import { FileMimeTypes } from '../../consts/mime-types-for-export';

export const httpNativeFetch: HttpFetch = {
  post(url, data, requestConfig?, customHeaders?: { [key: string]: string }) {
    return createFetchRequest(url, undefined, data, 'POST', requestConfig, customHeaders);
  },
  get(
    url,
    params?: Record<string, QueryParamType>,
    requestConfig?,
    customHeaders?: { [key: string]: string },
  ) {
    return createFetchRequest(url, params, {}, 'GET', requestConfig, customHeaders);
  },
  patch(url, data, requestConfig?, customHeaders?: { [key: string]: string }) {
    return createFetchRequest(url, undefined, data, 'PATCH', requestConfig, customHeaders);
  },
  put(url, data, requestConfig?, customHeaders?: { [key: string]: string }) {
    return createFetchRequest(url, undefined, data, 'PUT', requestConfig, customHeaders);
  },
  delete(
    url,
    params?: Record<string, QueryParamType>,
    requestConfig?,
    customHeaders?: { [key: string]: string },
  ) {
    return createFetchRequest(url, params, {}, 'DELETE', requestConfig, customHeaders);
  },
};

function createFetchRequest<TBody>(
  url: string,
  params: Record<string, QueryParamType> | undefined,
  data: TBody,
  type: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  requestConfig: RequestConfig = {},
  customHeaders: { [key: string]: string } = {},
): any {
  let headers: { [key: string]: string } = { 'Content-Type': 'application/json' };
  const body = type === 'GET' ? undefined : JSON.stringify(data);
  return new Promise((res, rej) => {
    headers = mergeCustomHeaders(customHeaders, headers);

    if (params) {
      url += `?${generateQueryParams(params)}`;
    }

    let timeoutTimerId: number | undefined;
    if (requestConfig.timeout) {
      timeoutTimerId = window.setTimeout(() => rej(new HttpTimeoutError()), requestConfig.timeout);
    }
    const start = performance.now();

    const fileTypes = Object.values(FileMimeTypes).map((v) => v + '');

    fetch(url, {
      method: type, // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers,
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
      referrerPolicy: 'same-origin',
      body, // тип данных в body должен соответвовать значению заголовка "Content-Type"
      signal: requestConfig.signal,
    })
      .finally(() => app.isDevMode && showRequestTime(start, url))
      .then((response) => {
        //console.log(response);
        if (response.ok) {
          cookieStorage.possibleCookieChanged();
          if (response.url.includes('login?doubleSession=true')) {
            // console.log(response.url);
            window.location.replace(response.url);
          }
          if (response.headers.get('Content-Type')?.includes('application/json')) {
            return response.json();
          } else if (fileTypes.includes(response.headers.get('Content-Type') || '')) {
            return response.blob();
          } else {
            return Promise.resolve({});
          }
        } else if (response.status === 401 && window.location.pathname !== '/login') {
          localStorageService.removeStorageValue('token');
          window.location.replace('/');
        } else if (response.status === 302 || response.status === 304) {
          localStorageService.removeStorageValue('token');
          window.location.replace('/');
          
        }else if (response.url.includes('login?doubleSession=true')) {
            // console.log(response.url);
            window.location.replace(response.url);
          }
        throw response;
      })
      .then((response) => {
        if (timeoutTimerId) {
          window.clearTimeout(timeoutTimerId);
        }
        res(response);
      })
      .catch(rej);
  }).catch((error) =>
    defineHttpRequestError(error, {
      ...headers,
      body: body || '',
    }).then((error: Error) => {
      throw error;
    }),
  );
}

function generateQueryParams(params: Record<string, QueryParamType>): string {
  const queryParams = new URLSearchParams();
  for (const key in params) {
    if (typeof params[key] === 'string') {
      queryParams.append(key, `${params[key]}`);
    } else if (params[key] instanceof Array) {
      (params[key] as Array<string | number>).forEach((param: string | number) =>
        queryParams.append(key, param.toString()),
      );
    }
  }
  return queryParams.toString();
}

function mergeCustomHeaders(
  customHeaders: { [key: string]: string },
  headers: { [key: string]: string },
): { [key: string]: string } {
  const token = localStorageService.getStorageValue('token');
  if (token) {
    const tokenObj = token as AuthenticationI;

    headers['Authorization'] = `Bearer  ${tokenObj.accessToken}`;
  }
  headers['Timezone-Name'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return { ...headers, ...customHeaders };
}

function showRequestTime(start: number, url: string) {
  const requestTime = performance.now() - start;
  // eslint-disable-next-line no-console
  console.log(`Request time ${requestTime > 1300 ? 'long' : ''} ${requestTime.toFixed(2)} ${url}`);
}
