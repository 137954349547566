import React, { useEffect, useState } from 'react';
import { authenticationService } from '../../../services/authentication/authentication';
import { localStorageService } from '../../../services/local-storage';

export interface AuthContextI {
  isAuth: boolean;
  token?: string;
  getAuth: () => boolean;

  onLogin?(email: string, password: string): Promise<string | null>;

  onLogout?(): void;

  onRegistration?(email: string, password: string, phone: string, name:string, gender:number, birthday:string, city:string, country: string, idRole: number): Promise<boolean>;
}

type Props = {
  children: React.ReactNode;
};

export const AuthContext = React.createContext<AuthContextI>({ token: undefined, isAuth: false, getAuth: () => false });

const AuthProvider = ({ children }: Props) => {
  const [token, setToken] = useState<string>();

  const handleLogin = async (email: string, password: string) => {
    const authenticationRs = await authenticationService.login(email, password);

    if (authenticationRs.accessToken) {
      setToken(authenticationRs.accessToken);
      return authenticationRs.accessToken;
    }

    return null;
  };

  const isAuth = (): boolean => {
    const token = localStorageService.getStorageValue('token');

    return !!token;
  };

  const handleRegistration = async (
    email: string, password: string, phone: string, name:string, gender:number, birthday:string, city:string, country: string, idRole: number
  ): Promise<boolean> => {
    return await authenticationService.registration(email, password, phone, name, gender, birthday, city, country, idRole);
  };

  const handleLogout = () => {
    authenticationService.logout();
  };

  const value: AuthContextI = {
    isAuth: isAuth(),
    token,
    getAuth: isAuth,
    onLogin: handleLogin,
    onLogout: handleLogout,
    onRegistration: handleRegistration,
  };

  useEffect(() => {
    isAuth();
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
