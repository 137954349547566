import { FilterFieldsState } from '../../models/product-filter/filter-fields';
import { filterInitialValue } from '../../../consts/filter-initial-values';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductFilterI } from '../../../core/entities/products/product-filter';

export const filterFieldsInitialState: FilterFieldsState = {
  ...filterInitialValue,
};

const filterFieldsSlice = createSlice({
  name: 'filterFields',
  initialState: filterFieldsInitialState,
  reducers: {
    setFilterFieldsState: (state, action: PayloadAction<ProductFilterI>) => {
      return action.payload;
    },
    resetFilterFieldsState: () => {
      return filterFieldsInitialState;
    },
  },
});

export const { setFilterFieldsState, resetFilterFieldsState } = filterFieldsSlice.actions;

export default filterFieldsSlice.reducer;
