import { AnalyticsProductsState } from '../../models/product-filter/product-filter';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { setProductsList } from '../../actions/product-filter/product-filter';
import { ProductListI } from '../../../core/entities/products/product';
import { setFilterFields } from '../../actions/product-filter/filter-fields';
import { FilterFields } from '../../../core/entities/products/product-filter';
import { ProductCountState } from '../../models/products/product';
import { setProductCount } from '../../actions/products/product';

export type AnalyticsPage = AnalyticsProductsState & ProductCountState;

const analyticsProductsState: AnalyticsPage = {
  productList: [],
  productCount: 0,
  fields: {
    brandsList: [],
    catalogsList: [],
    categoriesList: [],
    countriesList: [],
  },
};

export const analyticsProductsReducer = createReducer<AnalyticsPage>(analyticsProductsState, {
  [setProductsList.type]: (state, action: PayloadAction<ProductListI>) => {
    return { ...state, productList: action.payload.productList };
  },
  [setFilterFields.type]: (state, action: PayloadAction<FilterFields>) => ({
    ...state,
    fields: {...state.fields, ...action.payload},
  }),
  [setProductCount.type]: (state, action: PayloadAction<number>) => ({
    ...state,
    productCount: action.payload,
  }),
});
