import { AuthenticationI } from '../../entities/authentication/authentication';
import { LocalStorageService } from '../../../services/local-storage';

interface AuthenticationService {
  login(email: string, password: string): Promise<AuthenticationI>;
  logout():Promise<void>;

  registration(
    email: string,
    password: string,
    phone: string,
    name: string,
    gender: number,
    birthday: string,
    city: string,
    country: string,
    idRole: number,
  ): Promise<boolean>;
  confirmEmail(token: string): Promise<boolean>;

  saveTokenToStore(token: string): void;

  removeTokenToStore(): void;

  getListTitles(): Promise<any>;

  localStorageService: LocalStorageService;
}

interface Params {
  authenticationService: AuthenticationService;
}

export class AuthenticationUseCase {
  private authenticationService: AuthenticationService;
  private readonly localstorageName: string;

  constructor({ authenticationService }: Params) {
    this.authenticationService = authenticationService;
    this.localstorageName = 'token';
  }

  async login(email: string, password: string): Promise<AuthenticationI> {
    const { authenticationService } = this;

    const tokenRs = await authenticationService.login(email, password);
    authenticationService.saveTokenToStore(tokenRs.accessToken);
    authenticationService.localStorageService.setStorageValue(this.localstorageName, tokenRs);

    return tokenRs;
  }

 

  async getListTitles(): Promise<any> {
    const response = await this.authenticationService.getListTitles();
    return response;
  }

  async registration(
    email: string,
    password: string,
    phone: string,
    name: string,
    gender: number,
    birthday: string,
    city: string,
    country: string,
    idRole: number,
  ): Promise<boolean> {
    const { authenticationService } = this;

    return await authenticationService.registration(
      email,
      password,
      phone,
      name,
      gender,
      birthday,
      city,
      country,
      idRole,
    );
  }

  logout() {
    const { authenticationService } = this;

    authenticationService.logout();

    authenticationService.removeTokenToStore();
    authenticationService.localStorageService.removeStorageValue(this.localstorageName);
  }
  async confirmEmail(token: string) {
    const { authenticationService } = this;
    return await authenticationService.confirmEmail(token);
  }
}
