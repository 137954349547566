import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {RatingI, RatingKinds} from '../../../core/entities/rating/rating';
import {RatingStatisticsI} from "../../../core/entities/rating/rating-statistics";
import {RatingPageInitState} from "../../models/rating/rating";

const ratingPageInitState: RatingPageInitState = {
  id: 0,
  name: '',
  tradeMark: '',
  url: '',
  ogrn: '',
  revenueSum: 0,
  salesSum: 0,
  loseRevenueSum: 0,
  loseSalesSum: 0,
  ratingKind: RatingKinds.REVENUE,
};

const ratingPageInitSlice = createSlice({
  name: 'ratingPage',
  initialState: ratingPageInitState,
  reducers: {
    setRatingPage: (state, action: PayloadAction<RatingI>) => {
      const { id, url, name, tradeMark } = action.payload;
      return {
        ...state,
        id,
        name: name ?? '',
        url: url ?? '',
        tradeMark: tradeMark ?? '',
      };
    },
    setRatingStatisticsPage: (state, action: PayloadAction<RatingStatisticsI>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setRatingKind: (state, action: PayloadAction<RatingKinds>) => {
      return {
        ...state,
        ratingKind: action.payload,
      };
    },
  },
});

export const { setRatingPage, setRatingStatisticsPage, setRatingKind } = ratingPageInitSlice.actions;

export default ratingPageInitSlice.reducer;
