import './loading.scss';
import { bemCN } from '../../../../configs/bem-classname';

interface Props {
  color?: 'white' | 'dark';
}

const loadingCn = bemCN('loading');

const Loading = ({ color = 'white' }: Props) => (
  <div className={loadingCn('')}>
    <div className={loadingCn('background', { color })} />
    <div className={loadingCn('inner')}>
      <svg
        width="11.5rem"
        height="11.5rem"
        viewBox="0 0 184 164"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g className={loadingCn('left')}>
          <path
            d="M174.983 148.742L157.218 148.811L79.3572 49.8018L90.3921 41.1239L174.983 148.742Z"
            fill="#272264"
          />
          <path
            d="M97.7188 64.4346L79.3363 49.7671L106.856 14.8376L137.292 14.8376L97.7188 64.4346Z"
            fill="#272264"
          />
          <path
            d="M121.328 93.4004L110.303 84.6032L165.511 14.6591L183.998 14.8576L121.328 93.4004Z"
            fill="#272264"
          />
        </g>
        <g className={loadingCn('right')}>
          <path
            d="M105.02 113.788L96.6443 123.936L9.14611 14.8888L27.2616 14.6862L105.02 113.788Z"
            fill="#1D75BD"
          />
          <path
            d="M77.618 148.634L47.1492 148.747L84.0887 103.357L105.063 113.804L77.618 148.634Z"
            fill="#1D75BD"
          />
          <path
            d="M18.8545 148.746L-0.00153542 148.746L63.8994 69.3265L74.3749 78.56L18.8545 148.746Z"
            fill="#1D75BD"
          />
        </g>
      </svg>
    </div>
  </div>
);

export default Loading;
