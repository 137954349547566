import { HttpFetchResponse } from '../../../gateways/http-fetch/http-fetch-response';
import { ExtraParams } from '../../entities/error/error-handler-scope';
import { NotFoundObjectError } from '../errors/not-found-object-error';
import { UnauthorizedError } from '../errors/unauthorized';
import { NotFoundRequestedResourceError } from '../errors/not-found-requested-resource';
import { ForbiddenError } from '../errors/forbidden';
import { ServerUnavailableError } from '../errors/server-unavailable';
import { UnknownServerResponseError } from '../errors/unknown-server-response';
import { HttpTimeoutError } from '../errors/http-timeout';
import { BusinessError } from '../errors/business';

type BusinessErrorCode = 'bad_request';
export type ServerBusinessError = {
  code: BusinessErrorCode;
  errorMessage: string;
};

export function defineHttpRequestError(
  error?: HttpFetchResponse,
  context: ExtraParams = {},
): Promise<Error> {
  if (error) {
    return defineHttpRequestErrorByStatus(error, context);
  }
  return Promise.resolve(new NotFoundObjectError('server error'));
}

async function defineHttpRequestErrorByStatus(
  response: HttpFetchResponse,
  context: ExtraParams = {},
): Promise<Error> {
  const { status = undefined, statusText = '', url = undefined, message = '' } = typeof response === 'string' ? {message: response} : response;
  const text: string = response.text ? await response.text() : message as string;
  const extra: ExtraParams = {
    url: url as string,
    text,
    status,
    statusText,
    ...context,
  };
  if (!status) {
    return Promise.resolve(new NotFoundObjectError('status server error', extra));
  }

  switch (status) {
    case 400: {
      try {
        return defineBusinessError(JSON.parse(text));
      } catch (err: unknown) {
        return Promise.resolve(new BusinessError(`Unknown error ${err}`, extra));
      }
    }
    case 401: {
      return Promise.resolve(new UnauthorizedError('Unauthorized', extra));
    }
    case 403: {
      return Promise.resolve(new ForbiddenError('Forbidden', extra));
    }
    case 404: {
      return Promise.resolve(
        new NotFoundRequestedResourceError('Not found requested resource', extra),
      );
    }
    case 408:
    case 503:
    case 504: {
      return Promise.resolve(new HttpTimeoutError('HTTP timeout', extra));
    }
    case 500:
    case 501:
    case 502: {
      return Promise.resolve(new ServerUnavailableError(status, extra));
    }
    default:
      return Promise.resolve(new UnknownServerResponseError(status, statusText, extra));
  }
}

function defineBusinessError(response: unknown): Promise<Error> {
  if (checkBusinessError(response)) {
    const { code, errorMessage } = response;
    if (code === 'bad_request') {
      return Promise.resolve(new BusinessError(errorMessage || 'business error'));
    }
  }

  return Promise.resolve(new UnknownServerResponseError(400, 'business error'));
}

function checkBusinessError(response: unknown): response is ServerBusinessError {
  return typeof response === 'object' && response !== null && 'code' in response;
}
