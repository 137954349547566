import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { getFavoriteLabels, updateLabels } from '../../actions/favorite-product/favorite-product';
import { LabelsFavoriteState } from '../../models/favorite-products/favorite-labels';
import { FavoriteLabelI } from '../../../core/entities/product-favorite/favorite-labels';

const favoriteLabelsInitialState: LabelsFavoriteState = {
  labels: [],
};

export const favoriteLabelsReducer = createReducer<LabelsFavoriteState>(
  favoriteLabelsInitialState,
  {
    [getFavoriteLabels.type]: (state, action: PayloadAction<FavoriteLabelI[]>) => ({
      ...state,
      labels: action.payload,
    }),
    [updateLabels.type]: (state, action: PayloadAction<FavoriteLabelI[]>) => ({
      ...state,
      labels: [...action.payload],
    }),
  },
);
