import { SellersFilterI, SellersFilterQueryFields } from '../core/entities/sellers/seller-filter';

export const sellersFilterInitialValues: SellersFilterI = {
  name: '',
  inn: '',
  catalogsIds: [],
  categoryIds: [],
  brandsIds: [],
  active:true,
  navigation: {
    limit: 50,
    desc: true,
    offset: 0,
    sortFieldId:104
  },
};

export const sellersFilterFieldInitValues: SellersFilterQueryFields = {
  brandQuery: { query: '', selectedIds: [] },
  catalogsQuery: { query: '', selectedIds: [] },
  categoriesQuery: { query: '', selectedIds: [] },
};
