export interface LocalStorageService {
  getStorageValue(forService: string): string | Object | null;

  setStorageValue(storeKey: string, newStorageValue: unknown): void;

  removeStorageValue(forService: string): void;
}

type Values = Record<string, unknown>;

const storageKey = 'STORAGE_KEY';

export const localStorageService: LocalStorageService = {
  getStorageValue: (forService: string) => {
    const values = getValueFromLocalStorage();
    if (!isTokensObject(values)) {
      return null;
    }
    let value = values[forService];
    if (!value) {
      return null;
    }
    return value;
  },
  setStorageValue: (forService: string, newValue: unknown) => {
    const values = getValueFromLocalStorage();
    if (isTokensObject(values)) {
      values[forService] = newValue;
      persistValueToLocalStorage(values);
    }
  },
  removeStorageValue: (forService: string) => {
    const values = getValueFromLocalStorage();
    if (isTokensObject(values)) {
      delete values[forService];
      persistValueToLocalStorage(values);
    }
  },
};

function getValueFromLocalStorage(): unknown {
  return JSON.parse(window.localStorage.getItem(storageKey) || '{}');
}

function persistValueToLocalStorage(values: Values) {
  window.localStorage.setItem(storageKey, JSON.stringify(values));
}

function isTokensObject(values: unknown): values is Values {
  return typeof values === 'object';
}
