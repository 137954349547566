import { toCalendarDate } from '../utils/date';

export const lastYearRange: Readonly<[string, string]> = Object.freeze([
  toCalendarDate(
    new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate()),
  )!,
  toCalendarDate(new Date())!,
]);


export const lastMonthRange = new Date(new Date().setDate(new Date().getDate() - 30));
export const yeasteday = new Date(new Date().setDate(new Date().getDate() - 1));


export const getLastDays = (day:string, countDay:number) =>{
  let last = new Date(day);
  let newDays:string[] = [];

  for (let i = 0; i < countDay; i++) {
    let newDate = new Date(new Date().setDate(last.getDate() - 1));
    newDays.push(newDate.toLocaleString('en-GB', { day: '2-digit' }) + '.' + newDate.toLocaleString('en-GB', { month: '2-digit' }));
    last = newDate;
  }
  //let yeasteday = new Date(new Date().setDate(new Date(day).getDate() - 1));
  return newDays;
}