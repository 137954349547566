import { ProductPageState } from '../../models/product-page/product-page';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { getProductPage } from '../../actions/product-page/product-page';
import { ProductPageI } from '../../../core/entities/products/product-page';

const productPageInitialState: ProductPageState = {
  productPage: Object(),
};

export const productPageReducer = createReducer<ProductPageState>(productPageInitialState, {
  [getProductPage.type]: (state, action: PayloadAction<ProductPageI>) => ({
    ...state,
    productPage: action.payload,
  }),
});
