export function formatFullDate(dateTime: string | Date): string {
  if (!dateTime) {
    return '';
  }
  const dateObj = new Date(dateTime);
  const days = dateObj.getDate().toString().padStart(2, '0');
  const month = (dateObj.getMonth() + 1)?.toString().padStart(2, '0');
  const year = dateObj.getFullYear();

  return `${days}.${month}.${year}`;
}

export function toCalendarDate(dateTime?: string | Date): string | undefined {
  if (!dateTime) {
    return undefined;
  }

  const dateObj = new Date(dateTime);
  const days = dateObj.getDate().toString().padStart(2, '0');
  const month = (dateObj.getMonth() + 1)?.toString().padStart(2, '0');
  const year = dateObj.getFullYear();

  return `${year}-${month}-${days}`;
}
