import { AnalyticsCatalogsState } from '../../models/analytics-catalogs/analytics-catalogs';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnalyticCatalogI } from '../../../core/entities/catalogs/analytic-catalogs';

const analyticsCategoriesInitialState: AnalyticsCatalogsState = [];

const analyticsCatalogsSlice = createSlice({
  name: 'analyticsCatalogs',
  initialState: analyticsCategoriesInitialState,
  reducers: {
    setCatalogs: (state, action: PayloadAction<AnalyticCatalogI[]>) => {
      return action.payload;
    },
  },
});

export const { setCatalogs } = analyticsCatalogsSlice.actions;

export default analyticsCatalogsSlice.reducer;
