export class ProfileNotice {
  id: number;
  dateTime: Date;
  isRead: boolean;
  message: string;

  constructor(id: number, dateTime: string, isRead: boolean, message: string) {
    this.id = id;
    this.dateTime = new Date(dateTime);
    this.isRead = isRead;
    this.message = message;
  }
}
