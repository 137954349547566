import { SellerCountState } from '../../models/sellers/seller';
import { AnalyticsSellersState } from '../../models/seller-filter/seller-filter';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { setSellersList } from '../../actions/seller-filter/seller-filter';
import { SellersListI } from '../../../core/entities/sellers/sellers';
import { setSellerFilterFields } from '../../actions/seller-filter/seller-filter-fields';
import { SellersFilterFields } from '../../../core/entities/sellers/seller-filter';
import { setSellerCount } from '../../actions/sellers/sellers';
import { setSellersStatisticsList } from '../../actions/seller-filter/seller-statistics';
import { SellersStatisticsListI } from '../../../core/entities/sellers/sellers-statistics';
import { setSellersFields } from '../../actions/seller-filter/seller-fields';
import { FieldI } from '../../../core/entities/fields/fields';

export type AnalyticsSellersPage = AnalyticsSellersState & SellerCountState;

const analyticsSellersState: AnalyticsSellersPage = {
  sellerList: [],
  sellerStatisticsList: [],
  sellerCount: 0,
  fields: {
    brandsList: [],
    categoriesList: [],
    catalogsList: [],
  },
  columnFields: [],
};

export const analyticsSellersReducer = createReducer<AnalyticsSellersPage>(analyticsSellersState, {
  [setSellersList.type]: (state, action: PayloadAction<SellersListI>) => ({
    ...state,
    sellerList: action.payload.sellersList,
  }),
  [setSellerFilterFields.type]: (state, action: PayloadAction<SellersFilterFields>) => ({
    ...state,
    fields: {...state.fields, ...action.payload},
  }),
  [setSellersFields.type]: (state, action: PayloadAction<FieldI[]>) => ({
    ...state,
    columnFields: action.payload,
  }),
  [setSellerCount.type]: (state, action: PayloadAction<number>) => ({
    ...state,
    sellerCount: action.payload,
  }),
  [setSellersStatisticsList.type]: (state, action: PayloadAction<SellersStatisticsListI>) => ({
    ...state,
    sellerStatisticsList: action.payload?.sellersStatisticsList || [],
  }),
});
