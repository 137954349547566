import React from 'react';
import { Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Loading from './components/common/loading/loading';
import useRoutes from './hooks/routes';

const AppRouter = () => {
  const routes = useRoutes();

  const router = createBrowserRouter(routes);

  return (
    <Suspense fallback={<Loading />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default AppRouter;
