export const app = {
  get isDevMode(): boolean {
    return process.env.NODE_ENV === 'development';
  },
  get isTestMode(): boolean {
    return process.env.NODE_ENV === 'test';
  },
  get environment(): string | undefined {
    return process.env.REACT_APP_ENVIRONMENT;
  },
  // https://developers.google.com/search/docs/advanced/crawling/overview-google-crawlers#googlebot-desktop
  // https://yandex.com/support/webmaster/robot-workings/check-yandex-robots.html
  get isBotUserAgent(): boolean {
    return /googlebot|crawler|spider|robot|crawling|lighthouse|yandexbot|YandexAccessibilityBot|YandexMobileBot/i.test(
      navigator.userAgent,
    );
  },
  isHydrate: false,
};
