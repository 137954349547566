export enum SelectPeriods {
    WEEK = 'week',
    MONTH = 'month',
    YEAR = 'year',
    ALL_TIME = 'all-time',
}

export const getApiValueForPeriod = (p: SelectPeriods) => {
    switch (p) {
        case SelectPeriods.MONTH:
            return  0;
        case SelectPeriods.YEAR:
            return  1;
        default:
            return 2;
    }
}
