import { RatingFilterFieldsState } from '../../models/rating-filter/rating-filter-fields';
import { ratingFilterInitialValues } from '../../../consts/rating-filter-initial-values';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RatingFilterI } from '../../../core/entities/rating/rating-filter';

const ratingFilterFieldsInitialState: RatingFilterFieldsState = {
  ...ratingFilterInitialValues,
};

const ratingFilterFieldsSlice = createSlice({
  name: 'ratingFilterFields',
  initialState: ratingFilterFieldsInitialState,
  reducers: {
    setRatingFilterFieldsState: (state, action: PayloadAction<RatingFilterI>) => {
      return action.payload;
    },
    resetRatingFilterFieldsState: () => {
      return ratingFilterFieldsInitialState;
    },
  },
});

export const { setRatingFilterFieldsState, resetRatingFilterFieldsState } =
  ratingFilterFieldsSlice.actions;

export default ratingFilterFieldsSlice.reducer;
