import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { analyticsProductsReducer } from './reduces/product-filter/product-filter';
import { fieldsReducer } from './reduces/fields/fields';
import { productPageReducer } from './reduces/product-page/product-page';
import analyticsChartsReducer from './reduces/analytics-charts/analytics-charts';
import filterFieldsReducer from './reduces/product-filter/filter-fields';
import sellersFilterFieldsReducer from './reduces/seller-filter/seller-filter-fields';
import ratingFilterFieldsReducer from './reduces/rating/rating-filter-fields';
import analyticsCatalogsReducer from './reduces/analytics-catalogs/analytics-catalogs';
import subcategoryPageReducer from './reduces/subcategory-page/subcategory-page';
import calenderReducer from './reduces/calendar/calendar';
import periodReducer from './reduces/period/period';
import sellerPageReducer from './reduces/seller-page/seller-page';
import ratingPageReducer from './reduces/rating/rating-page';
import authenticationReducer from './reduces/authentication/authentication';
import { favoriteProductsReducer } from './reduces/favorite-products/favorite-products';
import { favoriteLabelsReducer } from './reduces/favorite-products/favorite-labels';
import { favoriteColorsReducer } from './reduces/favorite-products/favorite-colors';
import { analyticsSellersReducer } from './reduces/seller-filter/seller-filter';
import { analyticsRatingReducer } from './reduces/rating/rating-filter';
import { promotionQueriesReducer } from './reduces/promotion/queries/query-extension';
import promotionQueriesFilterFieldsReducer from './reduces/promotion/queries/filter-fields';
import {analyticsBrandsReducer} from './reduces/brands/brand-page';
import { noticesReducer } from './reduces/notices-reducer/notices-reducer';
import { profilePhotoReducer } from './reduces/profile-reducer/profile-reducer';

export const rootReducer = combineReducers({
  authentication: authenticationReducer,
  analyticsProducts: analyticsProductsReducer,
  analyticsSellers: analyticsSellersReducer,
  analyticsRating: analyticsRatingReducer,
  analyticsCatalogs: analyticsCatalogsReducer,
  analyticsCharts: analyticsChartsReducer,
  calender: calenderReducer,
  period: periodReducer,
  subcategoryPage: subcategoryPageReducer,
  productPage: productPageReducer,
  fields: fieldsReducer,
  filterFields: filterFieldsReducer,
  sellersFilterFields: sellersFilterFieldsReducer,
  sellerPage: sellerPageReducer,
  ratingFilterFields: ratingFilterFieldsReducer,
  ratingPage: ratingPageReducer,
  favoriteProducts: favoriteProductsReducer,
  favoriteLabels: favoriteLabelsReducer,
  favoriteColors: favoriteColorsReducer,
  promotionQueries: promotionQueriesReducer,
  promotionQueriesFilters: promotionQueriesFilterFieldsReducer,
  brandPage: analyticsBrandsReducer,
  noticesReducer: noticesReducer,
  profilePhotoReducer: profilePhotoReducer
});

export type RootState = ReturnType<typeof rootReducer>;

const initialState: Partial<RootState> = {};

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialState,
});

export const typedDispatch = store.dispatch;
