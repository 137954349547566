import { ProductFavoriteState } from '../../models/favorite-products/favorite-products';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { ProductFavoriteI } from '../../../core/entities/product-favorite/product-favorite';
import {
  getFavoriteProducts,
  updateFavoriteProducts,
} from '../../actions/favorite-product/favorite-product';

const favoriteProductsInitialState: ProductFavoriteState = {
  products: [],
};

export const favoriteProductsReducer = createReducer<ProductFavoriteState>(
  favoriteProductsInitialState,
  {
    [getFavoriteProducts.type]: (state, action: PayloadAction<ProductFavoriteI[]>) => ({
      ...state,
      products: action.payload,
    }),
    [updateFavoriteProducts.type]: (state, action: PayloadAction<ProductFavoriteI[]>) => ({
      ...state,
      products: [...action.payload],
    }),
  },
);
