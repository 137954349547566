import { SubcategoryPageState } from '../../models/subcategory-page/subcategory-page';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {SubcategoryChartI, SubcategoryStatisticsI} from '../../../core/entities/subcategory/subcategory';

const subcategoryPageState: SubcategoryPageState = {
  statistics: {
    catalogId: undefined,
    catalogName: '',
    categories: [],
    countSuppliers: 0,
    countBrends: 0,
    countArticuls: 0,
    countSuppliersSale: 0,
    countBrendsSale: 0,
    countArticulSales: 0,
  },
  chart: {
    sumRevenue: 0,
    sumLoseRevenue: 0,
    avgPrice: 0,
    avgRevenueDay: 0,
    avgBuyoutPercent:0
  }
};

export const subcategoryPageSlice = createSlice({
  name: 'subcategoryPage',
  initialState: subcategoryPageState,
  reducers: {
    setSubcategoryStatistics: (state, action: PayloadAction<SubcategoryStatisticsI>) => ({
      ...state,
      statistics: action.payload,
    }),
    setSubcategoryChart: (state, action: PayloadAction<SubcategoryChartI>) => ({
      ...state,
      chart: action.payload,
    }),
  },
});

export const { setSubcategoryStatistics, setSubcategoryChart } = subcategoryPageSlice.actions;

export default subcategoryPageSlice.reducer;
