import {SellersI} from "../sellers/sellers";
import {ProductI} from "../products/product";
import {FieldI} from "../fields/fields";

export enum RatingKinds {
  REVENUE = 'revenue',
  SALES = 'sales',
  PRODUCTS = 'products',
  PRODUCTS_SALES = 'productsWithSales',
  AVERAGE_RECEIPT = 'averageReceipt',
  SELLERS = 'sellers',
  SELLERS_SALES = 'sellersWithSales',
  BRANDS = 'brands',
  BRANDS_SALES = 'brands',
  AVERAGE_REVENUE_A_DAY = 'averageRevenuePerDay',
}

export interface RatingI {
  id: number;
  name?: string;
  tradeMark?: string;
  ogrn?: string;
  url?: string;
}
export type RatingsI = SellersI | ProductI;

export interface RatingListI {
  ratingList: RatingsI[];
}
export interface RatingsStatisticsI {
  id: number;
  revenueSum: number;
  salesSum: number;
  loseRevenueSum: number;
  loseSalesSum: number;
}

export const isProductsTable = (ratingKind: RatingKinds) =>
  [RatingKinds.REVENUE, RatingKinds.SALES, RatingKinds.AVERAGE_REVENUE_A_DAY].includes(ratingKind);
export const isSellerTable = (ratingKind: RatingKinds) => !isProductsTable(ratingKind);

export const getRatingTableTotalCount = (ratingKind: RatingKinds) => isProductsTable(ratingKind) ? 5000 : 1000;

export const getTitleByRatingKind = (ratingKind: RatingKinds): string => {
  switch (ratingKind) {
    case RatingKinds.REVENUE:
      return 'ТОП-5000 категорий по выручке';
    case RatingKinds.SALES:
      return 'ТОП-5000 категорий по продажам';
    case RatingKinds.PRODUCTS:
      return 'ТОП-1000 продавцов по товарам';
    case RatingKinds.PRODUCTS_SALES:
      return 'ТОП-1000 продавцов по товарам с продажами';
    case RatingKinds.AVERAGE_RECEIPT:
      return 'ТОП-1000 продавцов по среднему чеку';
    case RatingKinds.SELLERS:
      return 'ТОП-5000 категорий по продавцам';
    case RatingKinds.SELLERS_SALES:
      return 'ТОП-5000 категорий по продавцам с продажами';
    case RatingKinds.BRANDS:
      return 'ТОП-1000 продавцов по брендам';
    case RatingKinds.BRANDS_SALES:
      return 'ТОП-1000 продавцов по брендам с продажами';
    case RatingKinds.AVERAGE_REVENUE_A_DAY:
      return 'ТОП-5000 категорий по средней выручке';
    default:
      return '';
  }
};

export const getSortFieldId = (ratingKind: RatingKinds, fields: FieldI[]): {sortFieldId?: number; desc: boolean} => {
  let sortName = 'name';
  let desc = true;
  switch (ratingKind
      ) {
    case RatingKinds.REVENUE:
      sortName = 'revenue';
      break;
    case RatingKinds.SALES:
      sortName = 'countSale';
      break;
    case RatingKinds.PRODUCTS:
      sortName = 'productWithSaleCount'; //todo
      break;
    case RatingKinds.PRODUCTS_SALES:
      sortName = 'productWithSaleCount';
      break;
    case RatingKinds.AVERAGE_RECEIPT:
      sortName = 'productWithSaleCount'; //todo
      break;
    case RatingKinds.SELLERS:
      sortName = 'saleCount'; //todo
      break;
    case RatingKinds.SELLERS_SALES:
      sortName = 'saleCount'; //todo
      break;
    case RatingKinds.BRANDS:
      sortName = 'brandName';
      break;
    case RatingKinds.BRANDS_SALES:
      sortName = 'brandName'; //todo
      break;
    case RatingKinds.AVERAGE_REVENUE_A_DAY:
      sortName = 'avgRevenuePerDay'; //todo
      break;
  }
  return {sortFieldId: fields.find(f => f.siteFieldName === sortName)?.id, desc};
}
