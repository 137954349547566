import { ExtraParams } from '../../entities/error/error-handler-scope';
import { BaseHttpError } from './base-http-error';

export class UnknownServerResponseError extends BaseHttpError {
  readonly status: number;
  readonly statusText: string;

  constructor(status: number, statusText: string, extra: ExtraParams = {}) {
    super(`status = ${status}`, extra);
    this.status = status;
    this.statusText = statusText;
    this.name = 'UnknownServerResponseError';
  }
}
