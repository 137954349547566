import { AuthenticationInitState } from '../../models/authentication/authentication';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const authenticationInitState: AuthenticationInitState = {
  token: undefined,
};

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: authenticationInitState,
  reducers: {
    getToken: (state, action: PayloadAction<string>) => ({
      ...state,
      token: action.payload,
    }),
    removeToken: (state) => (state.token = undefined),
  },
});

export const { getToken, removeToken } = authenticationSlice.actions;

export default authenticationSlice.reducer;
