import { nsCtor } from '../utils/namespace-constructor';

const apiSeparator = '/';
const apiCommonPrefix = '/api';
const apiAuthPrefix = `${apiCommonPrefix}${apiSeparator}Auth`;
const apiCategoriesPrefix = `${apiCommonPrefix}${apiSeparator}Categories`;
const apiFieldsPrefix = `${apiCommonPrefix}${apiSeparator}Fields`;
const apiProductCardPrefix = `${apiCommonPrefix}${apiSeparator}ProductCard`;
const apiProductFilterPrefix = `${apiCommonPrefix}${apiSeparator}ProductFilter`;
const apiSellersFilterPrefix = `${apiCommonPrefix}${apiSeparator}Sellers`;
const apiSellerPagePrefix = `${apiCommonPrefix}${apiSeparator}SellerCard`;
const apiRatingFilterPrefix = `${apiCommonPrefix}${apiSeparator}Sellers`;
const apiRatingPagePrefix = `${apiCommonPrefix}${apiSeparator}SellerCard`;
const apiFavoriteProductPrefix = `${apiCommonPrefix}${apiSeparator}FavoriteProducts`;
const apiBrandPrefix = `${apiCommonPrefix}${apiSeparator}Brand`;
const apiProfilePrefix = `${apiCommonPrefix}${apiSeparator}Profile`;
const apiBranCardPrefix = `${apiCommonPrefix}${apiSeparator}BrandCard`;
const apiHistoryPrefix = `${apiCommonPrefix}${apiSeparator}History`;
const apiKeyPrefix = `${apiCommonPrefix}${apiSeparator}ApiKey`;
const campaignPrefix = `${apiCommonPrefix}${apiSeparator}Campaign`;
const autoCampaignPrefix = `${apiCommonPrefix}${apiSeparator}AutoCampaign`;
const searchCampaignPrefix = `${apiCommonPrefix}${apiSeparator}SeacatCampaign`;
const dictionaryPrefix = `${apiCommonPrefix}${apiSeparator}Dictionary`;
const financePrefix = `${apiCommonPrefix}${apiSeparator}Finance`;
const paymentPrefix = `${apiCommonPrefix}${apiSeparator}Payment`;
const feedBackPrefix = `${apiCommonPrefix}${apiSeparator}Feedback`;

const apiAuthNS = nsCtor(apiAuthPrefix, apiSeparator);
const apiCategoriesNS = nsCtor(apiCategoriesPrefix, apiSeparator);
const apiFieldsNS = nsCtor(apiFieldsPrefix, apiSeparator);
const apiProductCardNS = nsCtor(apiProductCardPrefix, apiSeparator);
const apiProductFilterNS = nsCtor(apiProductFilterPrefix, apiSeparator);
const apiSellersFilterNS = nsCtor(apiSellersFilterPrefix, apiSeparator);
const apiSellerPageNS = nsCtor(apiSellerPagePrefix, apiSeparator);
const apiRatingFilterNS = nsCtor(apiRatingFilterPrefix, apiSeparator);
const apiRatingPageNS = nsCtor(apiRatingPagePrefix, apiSeparator);
const apiFavoriteProductNS = nsCtor(apiFavoriteProductPrefix, apiSeparator);
const apiBrandNS = nsCtor(apiBrandPrefix, apiSeparator);
const apiProfileNS = nsCtor(apiProfilePrefix, apiSeparator);
const apiBrandCardNS = nsCtor(apiBranCardPrefix, apiSeparator);
const apiHistoryNS = nsCtor(apiHistoryPrefix, apiSeparator);
const apiKeyNS = nsCtor(apiKeyPrefix, apiSeparator);
const campaignNS = nsCtor(campaignPrefix, apiSeparator);
const autoCampaignNS = nsCtor(autoCampaignPrefix, apiSeparator);
const searchCampaignNS = nsCtor(searchCampaignPrefix, apiSeparator);
const dictionaryNS = nsCtor(dictionaryPrefix, apiSeparator);
const financeNS = nsCtor(financePrefix, apiSeparator);
const paymentNS = nsCtor(paymentPrefix, apiSeparator);
const feedBackNS = nsCtor(feedBackPrefix, apiSeparator);

export const apiPathConfig = {
  Auth: {
    login: apiAuthNS`Login`,
    registration: apiAuthNS`Registration`,
    confirmEmail: apiAuthNS`ConfirmEmail`,
    recoverPassword: apiAuthNS`RecoverPassword`,
    updatePassword: apiAuthNS`UpdatePassword`,
    getListTitles: apiAuthNS`GetListTitles`,
    logout: apiAuthNS`Logout`,
  },
  Categories: {
    GetCatalogs: apiCategoriesNS`GetCatalogs`,
    GetCategories: apiCategoriesNS`GetCategories`,
    CategoryStatistics: apiCategoriesNS`GetCategoryStatisticsFilter`,
    GetCategoryStatistics: apiCategoriesNS`GetCategoryStatistics`,
  },
  Fields: {
    GetFields: apiFieldsNS`GetFields`,
  },
  ProductCard: {
    GetProductCard: apiProductCardNS`GetProductCard`,
    GetProductCardStatistics: apiProductCardNS`GetProductCardStatistics`,
  },
  FavoriteProduct: {
    GetFavoriteProduct: apiFavoriteProductNS`GetProducts`,
    GetAllLabelColors: apiFavoriteProductNS`GetAllLabelCorors`, //получает все цвета
    GetLabels: apiFavoriteProductNS`GetLabels`, //получает метки групп для конкретного пользователя
    UpdateLabel: apiFavoriteProductNS`UpdateLabel`, //обновляет метку по id
    InsertProductLabel: apiFavoriteProductNS`InsertProductLabel`, //Добавление метки продукту
    DeleteProductLabel: apiFavoriteProductNS`DeleteProductLabel`, //Удаление метки у продукта
    UpdateProduct: apiFavoriteProductNS`UpdateLabelProduct`, //обновление метки продукта
    СreateDownloadFile: apiFavoriteProductNS`CreateDownloadFile`,

  },
  ProductFilter: {
    GetCountProducts: apiProductFilterNS`GetCountProducts`,
    GetCountBrends: apiProductFilterNS`GetCountBrends`,
    GetCountSales: apiProductFilterNS`GetCountSales`,
    GetProducts: apiProductFilterNS`GetProducts`,
    GetProductsByDate: apiProductFilterNS`GetProductsByDate`,
    GetBrands: apiProductFilterNS`GetBrands`,
    GetCatalogs: apiProductFilterNS`GetCatalogs`,
    GetCategories: apiProductFilterNS`GetCategories`,
    GetCountries: apiProductFilterNS`GetCountries`,
    GetCountSellers: apiProductFilterNS`GetCountSellers`,
    createDownloadFile: apiProductFilterNS`CreateDownloadFile`,
    createDownloadFileByDate: apiProductFilterNS`CreateDownloadFileByDate`,
    getFileById: apiProductFilterNS`GetFileById`,
    getSumRevenue: apiProductFilterNS`GetSumRevenue`,
    getAvgRevenueDay: apiProductFilterNS`GetAvgRevenueDay`,
    getAvgPrice: apiProductFilterNS`GetAvgPrice`,
    sumLoseRevenue: apiProductFilterNS`SumLoseRevenue`,
    getAvgBuyoutPercent: apiProductFilterNS`GetAvgBuyoutPercent`,
  },
  SellersFilter: {
    GetSellers: apiSellersFilterNS`GetSellers`,
    GetStatistics: apiSellersFilterNS`GetStatistics`,
    GetFieldsSeller: apiSellersFilterNS`GetFieldsSeller`,
    getSellersCount: apiSellersFilterNS`GetCountSellers`,
    createDownloadFile: apiSellersFilterNS`CreateDownloadFile`,
    getFileById: apiSellersFilterNS`GetFileById`,
  },
  Seller: {
    getSeller: apiSellerPageNS`Seller`,
    getStatistics: apiSellerPageNS`GetSellerStatisticsByFilter`,
  },
  Brands: {
    GetBrands: apiBrandNS`GetBrands`,
    GetBrandsCount: apiBrandNS`CountResults`,
    CreateDownloadFile: apiBrandNS`CreateDownloadFile`,
    GetFileById: apiBrandNS`GetFileById`,
    GetFields: apiBrandNS`GetFields`,
  },
  RatingFilter: {
    GetRating: apiRatingFilterNS`GetSellers`,
    GetStatistics: apiRatingFilterNS`GetStatistics`,
    GetFieldsRating: apiRatingFilterNS`GetFieldsSeller`,
  },
  Rating: {
    getRating: apiRatingPageNS`Seller`,
    getStatistics: apiRatingPageNS`Statistics`,
    GetCountRating: apiProductFilterNS`GetCountSellers`, //TODO
  },
  QueryExtension: {
    GetCountQueryExtensions: ``,
    GetQueryExtensions: ``,
    CreateDownloadFile: ``,
    GetFileById: ``,
  },
  Profile: {
    getProfile: apiProfileNS`GetProfile`,
    getPhoto: apiProfileNS`GetPhoto2`,
    setName: apiProfileNS`SetName`,
    setPhone: apiProfileNS`SetPhoneNumber`,
    setPhoto: apiProfileNS`SetPhoto2`,
    setPassword: apiProfileNS`ChanagePassword`,
    getCurrentSubscription: apiProfileNS`GetCurrentSubscription`,
  },
  Brand: {
    getBrand: apiBrandCardNS`GetBrand`,
    getBrandStatistics: apiBrandCardNS`GetBrandStatisticsFilter`,
  },
  History: {
    getHistory: apiHistoryNS`GetHistory`,
    getCount: apiHistoryNS`GetCount`,
  },
  ApiKey: {
    addApiKey: apiKeyNS`AddApiKey`,
    getApiKey: apiKeyNS`GetApiKeysByOwner`,
    deleteApiKey: apiKeyNS`DeleteApiKey`,
    getCabinets: apiKeyNS`GetCabinets`,
  },
  Campaign: {
    get: campaignNS``,
    start: campaignNS`Start`,
    pause: campaignNS`Pause`,
    stop: campaignNS`Stop`,
    changeName: campaignNS`ChangeName`,
    getStatistics: campaignNS`GetStatistics`,
    pageCount: campaignNS`PageCount`,
    delete: campaignNS``,
    addBalance: campaignNS`AddBalance`
  },
  AutoCampaign: {
    get: autoCampaignNS``,
    post: autoCampaignNS``,
    patch: autoCampaignNS``,
  },
  SeachCampaign: {
    post: searchCampaignNS``,
    get: searchCampaignNS``,
  },
  Dictionary: {
    getCategories: dictionaryNS`GetCategories`,
    getProducts: dictionaryNS`GetProducts`,
  },
  Finance: {
    get: financeNS``,
  },
  Payment: {
    getSellableItems: paymentNS`SellableItems`,
    init: paymentNS`Init`,
    notify: paymentNS`Notify`,
    orders: paymentNS`Orders`,
  },
  Feedback: {
    getSpecialties: feedBackNS`GetSpecialties`,
    sendFeedback: feedBackNS`FeedbackForm`,
  },
};
