import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SelectPeriods } from '../../../consts/select-period';

export const periodSlice = createSlice({
  name: 'period',
  initialState: { period: SelectPeriods.ALL_TIME },
  reducers: {
    setPeriod: (state, action: PayloadAction<SelectPeriods>) => ({
      ...state,
      period: action.payload,
    }),
  },
});

export const { setPeriod } = periodSlice.actions;

export default periodSlice.reducer;
