import { FilterQueryFields, ProductFilterI } from '../core/entities/products/product-filter';
import { lastMonthRange, yeasteday } from './calendar';

export const filterInitialValue: ProductFilterI = {
  name: '',
  limit: 50,
  offset: 0,
  brandIds: [],
  categoryIds: [],
  countryIds: [],
  subCategoriesIds: [],
  desc: true,
  dateFrom: lastMonthRange.toISOString().substring(0, 10),
  dateTo: yeasteday.toISOString().substring(0, 10),
  sortFieldId: 666
};

export const filterFieldInitValues: FilterQueryFields = {
  brandQuery: { query: '', selectedIds: [] },
  catalogsQuery: { query: '', selectedIds: [] },
  categoriesQuery: { query: '', selectedIds: [] },
  countriesQuery: { query: '', selectedIds: [] },
};
