import { createAction } from '@reduxjs/toolkit';
import { RatingListI } from '../../../core/entities/rating/rating';
import { RatingFilterFields } from '../../../core/entities/rating/rating-filter';
import { RatingStatisticsListI } from '../../../core/entities/rating/rating-statistics';
import {FieldI} from "../../../core/entities/fields/fields";

export const ratingActions = {
  setRatingCount: createAction<number>('SET_RATING_COUNT'),
  setRatingList: createAction<RatingListI>('SET_RATING_LIST'),
  setRatingFilterFields: createAction<RatingFilterFields>('SET_RATING_FILTER_FIELDS'),
  setRatingStatisticsList: createAction<RatingStatisticsListI>('SET_RATING_STATISTICS_LIST'),
  setRatingsFields: createAction<FieldI[]>('SET_RATING_FIELDS'),
};
