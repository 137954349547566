import { profileNotices } from '../../../consts/profile-notices';
import { ProfileNotice } from '../../../core/entities/profile-notice/profile-notice';
import { NoticeActions } from './notices-actions';

const defaultState = {
  notices: profileNotices.sort((a: ProfileNotice, b: ProfileNotice) => b.dateTime.getTime() - a.dateTime.getTime()),
};

export const noticesReducer = (_state = defaultState, _action: any) => {
  switch (_action.type) {
    case NoticeActions.DELETE_NOTICE: {
      return { ..._state, notices: [..._state.notices.filter((n) => n.id !== _action.payload)] };
    }
    case NoticeActions.UPDATE_NOTICE: {
      let newNotice = [..._state.notices].map((n) => {
        if (n.id === _action.payload) return {...n, isRead: !n.isRead};
        else return n;
      });
      return { ..._state, notices: [...newNotice] };
    }
    default: {
      return _state;
    }
  }
};
