import { HttpFetch } from './http-fetch/http-fetch';
import { apiPathConfig } from '../consts/api-path';
import { AuthenticationI } from '../core/entities/authentication/authentication';

export interface AuthenticationFetchI {
  login(email: string, password: string): Promise<AuthenticationI>;

  logout():Promise<void>;

  registration(
    email: string,
    password: string,
    phone: string,
    name: string,
    gender: number,
    birthday: string,
    city: string,
    country: string,
    idRole: number,
  ): Promise<boolean>;
  confirmEmail(id: string): Promise<boolean>;

  getListTitles(): Promise<any>;
}

export class AuthenticationFetch implements AuthenticationFetchI {
  private readonly httpFetch: HttpFetch;

  constructor(httpFetch: HttpFetch) {
    this.httpFetch = httpFetch;
  }

  async login(email: string, password: string): Promise<AuthenticationI> {
    return await this.httpFetch.post<{ email: string; passwordHash: string }, AuthenticationI>(
      apiPathConfig.Auth.login,
      { email, passwordHash: password },
    );
  }

  async getListTitles(): Promise<any> {
    return await this.httpFetch.post(apiPathConfig.Auth.getListTitles, {});
  }

  async registration(
    email: string,
    passwordHash: string,
    phoneNumber: string,
    name: string,
    gender: number,
    birthday: string,
    city: string,
    country: string,
    jobTitle: number,
  ): Promise<boolean> {
    return await this.httpFetch
      .post<
        {
          email: string;
          passwordHash: string;
          phoneNumber: string;
          name: string;
          gender: number;
          birthday: string;
          city: string;
          country: string;
          jobTitle: number;
        },
        {}
      >(apiPathConfig.Auth.registration, {
        email,
        passwordHash,
        phoneNumber,
        name,
        gender,
        birthday,
        city,
        country,
        jobTitle,
      })
      .then(() => {
        return true;
      });
  }

  async confirmEmail(id: string): Promise<boolean> {
    return await this.httpFetch.get<boolean>(apiPathConfig.Auth.confirmEmail, { id });
  }


  async logout(){
    await this.httpFetch.get(apiPathConfig.Auth.logout);
  }
}
