import { SellerFilterFieldsState } from '../../models/seller-filter/seller-filter-fields';
import { sellersFilterInitialValues } from '../../../consts/sellers-filter-initial-values';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SellersFilterI } from '../../../core/entities/sellers/seller-filter';

const sellerFilterFieldsInitialState: SellerFilterFieldsState = {
  ...sellersFilterInitialValues,
};

const sellersFilterFieldsSlice = createSlice({
  name: 'sellersFilterFields',
  initialState: sellerFilterFieldsInitialState,
  reducers: {
    setSellersFilterFieldsState: (state, action: PayloadAction<SellersFilterI>) => {
      return action.payload;
    },
    resetSellersFilterFieldsState: () => {
      return sellerFilterFieldsInitialState;
    },
  },
});

export const { setSellersFilterFieldsState, resetSellersFilterFieldsState } =
  sellersFilterFieldsSlice.actions;

export default sellersFilterFieldsSlice.reducer;
