
const defaultState = {
  profilePhoto: null,
};

export const profilePhotoReducer = (_state = defaultState, _action: any) => {
  switch (_action.type) {
    case "SET_PHOTO": {
      if(_action.payload==="null" || _action.payload==="") return { ..._state, profilePhoto: defaultState.profilePhoto};
      else return { ..._state, profilePhoto: _action.payload};
    }
    default: {
      return _state;
    }
  }
};
