import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsRatingState } from '../../models/rating-filter/rating-filter';
import { RatingCountState } from '../../models/rating/rating';
import { RatingListI } from '../../../core/entities/rating/rating';
import { RatingFilterFields } from '../../../core/entities/rating/rating-filter';
import { RatingStatisticsListI } from '../../../core/entities/rating/rating-statistics';
import { ratingActions } from '../../actions/rating/rating';
import { FieldI } from '../../../core/entities/fields/fields';

export type AnalyticsRatingPage = AnalyticsRatingState & RatingCountState;

const analyticsRatingState: AnalyticsRatingPage = {
  ratingList: [],
  ratingStatisticsList: [],
  ratingCount: 0,
  fields: {
    brandsList: [],
    categoriesList: [],
    catalogsList: [],
    countriesList: [],
  },
  columnFields: [],
};

export const analyticsRatingReducer = createReducer<AnalyticsRatingPage>(analyticsRatingState, {
  [ratingActions.setRatingList.type]: (state, action: PayloadAction<RatingListI>) => ({
    ...state,
    ratingList: action.payload.ratingList,
  }),
  [ratingActions.setRatingFilterFields.type]: (
    state,
    action: PayloadAction<RatingFilterFields>,
  ) => ({
    ...state,
    fields: action.payload,
  }),
  [ratingActions.setRatingCount.type]: (state, action: PayloadAction<number>) => ({
    ...state,
    ratingCount: action.payload,
  }),
  [ratingActions.setRatingsFields.type]: (state, action: PayloadAction<FieldI[]>) => ({
    ...state,
    columnFields: action.payload,
  }),
  [ratingActions.setRatingStatisticsList.type]: (
    state,
    action: PayloadAction<RatingStatisticsListI>,
  ) => ({
    ...state,
    ratingStatistics: action.payload,
  }),
});
