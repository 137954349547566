import { ProfileNotice } from "../core/entities/profile-notice/profile-notice";


export const profileNotices = [
    new ProfileNotice(1, "2024-03-02 15:27", true, "Необходимо оплатить аккаунт до 16.04.2025"),
    new ProfileNotice(2, "2024-03-03 10:04", true, "Необходимо оплатить аккаунт до 16.04.2025"),
    new ProfileNotice(3, "2024-03-06  18:23", false, "Необходимо оплатить аккаунт до 16.04.2025"),
    new ProfileNotice(4, "2024-03-07  09:10", false, "Необходимо оплатить аккаунт до 16.04.2025"),
    new ProfileNotice(5, "2024-03-10  20:45", true, "Необходимо оплатить аккаунт до 16.04.2025"),
    new ProfileNotice(6, "2024-03-27  07:34", false, "Необходимо оплатить аккаунт до 16.04.2025"),
    new ProfileNotice(7, "2024-04-12  14:50", false, "Необходимо оплатить аккаунт до 16.04.2025"),
    new ProfileNotice(8, "2024-04-15  13:03", false, "Необходимо оплатить аккаунт до 16.04.2025"),


]