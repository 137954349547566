import { ExtraParams } from '../../entities/error/error-handler-scope';

export class BaseHttpError extends Error {
  extra: ExtraParams;

  constructor(message: string, extra: ExtraParams = {}) {
    super(message);
    this.extra = extra;
  }
}
