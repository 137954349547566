import { SellerPageInitState } from '../../models/seller-page/seller-page';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SellerI, SellerStatisticsI } from '../../../core/entities/seller/seller';

const sellerPageInitState: SellerPageInitState = {
  id: 0,
  name: '',
  tradeMark: '',
  url: '',
  ogrn: '',
  revenueSum: 0,
  salesSum: 0,
  loseRevenueSum: 0,
  loseSalesSum: 0,
};

const sellerPageInitSlice = createSlice({
  name: 'sellerPage',
  initialState: sellerPageInitState,
  reducers: {
    setSellerPage: (state, action: PayloadAction<SellerI>) => {
      const { id, url, name, tradeMark, ogrn } = action.payload;
      return {
        ...state,
        id,
        name: name ?? '',
        url: url ?? '',
        tradeMark: tradeMark ?? '',
        ogrn: ogrn ?? '',
      };
    },
    setSellerStatisticsPage: (state, action: PayloadAction<SellerStatisticsI>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setSellerPage, setSellerStatisticsPage } = sellerPageInitSlice.actions;

export default sellerPageInitSlice.reducer;
