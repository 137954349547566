import { AnalyticsChartsState } from '../../models/analytics-charts/analytics-charts';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const analyticsChartsState: AnalyticsChartsState = {
  productCount: 0,
  brandsCount: 0,
  salesCount: 0,
  sellersCount: 0,
  loading: false,
};

export const analyticsChartsSlice = createSlice({
  name: 'analyticsCharts',
  initialState: analyticsChartsState,
  reducers: {
    setProductCountChart: (state, action: PayloadAction<number>) => ({
      ...state,
      productCount: action.payload,
    }),
    setBrandCountChart: (state, action: PayloadAction<number>) => ({
      ...state,
      brandsCount: action.payload,
    }),
    setSalesCountChart: (state, action: PayloadAction<number>) => ({
      ...state,
      salesCount: action.payload,
    }),
    setSellersCountCountChart: (state, action: PayloadAction<number>) => ({
      ...state,
      sellersCount: action.payload,
    }),
    setLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload,
    }),
  },
});

export const {
  setProductCountChart,
  setSellersCountCountChart,
  setSalesCountChart,
  setBrandCountChart,
  setLoading,
} = analyticsChartsSlice.actions;

export default analyticsChartsSlice.reducer;
